<template>
  <div :class="['flex flex-col', { 'mx-6 mt-6 mb-0': applyMargin }]">
    <div
      class="table-actions w-max overflow-hidden flex items-center align-center"
    >
      <div v-if="showActions" class="flex items-center h-full">
        <filter-button
          v-if="showFilter || showSearchFilter"
          class="h-full text-th-color-header"
          :model-value="injectFilter"
          :filters="searchFiltersWithData"
          @filter="handleSearchFilterInput"
          @submit="handleSearchFilterSubmit"
          @update-filter="handleUpdateFilter"
        />
        <search-input
          v-if="showSearch || showSearchFilter"
          class="h-full"
          :model-value="injectFilter"
          :filters="searchFiltersWithData"
          @filter="handleSearchFilterInput"
          @submit="handleSearchFilterSubmit"
        />
      </div>
      <slot />
    </div>
    <chips-viewer
      v-if="showSearch || showFilter || showSearchFilter"
      class="w-max"
      text-field-name="q"
      :model-value="injectFilter"
      :filters="searchFiltersWithData"
      @submit="handleSearchFilterSubmit"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import filterObject from 'just-filter-object'
import qs from 'qs'
import mapValues from 'just-map-values'
import safeSet from 'just-safe-set'
import FilterButton from '@/components/filter-header/filter'
import SearchInput from '@/components/filter-header/search'
import ChipsViewer from '@/components/filter-header/chips-viewer'

export default {
  name: 'ThDatatableAction',
  components: {
    ChipsViewer,
    FilterButton,
    SearchInput
  },
  props: {
    /**
     * Define which SDK resource is allowed and will be built.
     */
    resource: {
      type: [String, Boolean],
      required: true,
      validator: (resource) => {
        if (resource === false) return true
        return !!th[resource]
      }
    },
    customResource: {
      type: Object,
      default: undefined
    },
    /**
     * Drive behaviour in exports buttons
     */
    exportOptions: {
      type: Object,
      default: () => ({})
    },
    /**
     * Toggle visibility of the action bar.
     */
    showActions: {
      type: Boolean,
      default: true
    },
    /**
     * Toggle visibility of the search and filter.
     */
    showSearchFilter: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * Toggle visibility of the search.
     */
    showSearch: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * Toggle visibility of the filter.
     */
    showFilter: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * Search and filters config and values.
     */
    searchFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Define whether to route on filters being set. This e.g. triggers query parameters in the browser
     */
    doRouteFilters: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * Define a base for routing, e.g. if you do not wish to route on the current URL.
     */
    routeBase: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Prune superfluous filters from the search filters. This essentially sanitizes the UI, not to display tags that have not been intended
     */
    pruneSearchFilters: {
      type: [Boolean, Function],
      required: false,
      default: undefined
    },
    /**
     * Override parsedFilter result internally
     */
    parsedFilterValue: {
      type: Object,
      default: null
    },
    /**
     * Should margin be applied to the filter element
     */
    applyMargin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filtersData: {}
    }
  },
  computed: {
    searchFiltersWithData() {
      // Inject filters data
      const copyOfFilters = [...this.searchFilters]
      copyOfFilters.forEach((f) => {
        f.originalData = this.filtersData[f.name]
      })
      return copyOfFilters
    },
    injectFilter() {
      if (!this.parsedFilter || !this.searchFilters) return {}
      const filters = {}
      Object.keys(this.parsedFilter).forEach((key) => {
        const map = this.searchFilters.find((item) => item.name === key)
        if (!map) {
          filters[key] = {
            value: this.parsedFilter[key],
            name: key
          }
          return
        }
        filters[key] = {
          label: map.label,
          value: this.parsedFilter[key],
          name: key
        }
      })
      // NOTE: this is a patch, as feature, to mitigate quirky query param behavior. Essentially we do not want to
      // add tags to the search input, that have been defined in the search filter list
      if (typeOf(this.pruneSearchFilters) === 'boolean') {
        return filterObject(
          filters,
          (key, value) => !!this.searchFilters.find((item) => item.name === key)
        )
      }
      if (typeOf(this.pruneSearchFilters) === 'function') {
        return this.pruneSearchFilters(filters)
      }
      return filters
    },
    parsedFilter() {
      //parsed filter could be passed as will be shown from value and not from route parameters
      if (this.parsedFilterValue) return this.parsedFilterValue
      if (this.$route && this.$route.query) {
        const filter = qs.parse(this.$route.query) || {}
        this.$emit('handle-parsed-filter', filter.filter)
        return filter.filter
      }
      return null
    },
    routeBaseDefault() {
      return this.routeBase || `/${this.resource || this.customResource}`
    }
  },
  methods: {
    getResourceOptions() {
      let resourceOptions = {}
      if (this.resourceLimit) {
        resourceOptions.limit = this.resourceLimit
      }

      if (this.resourceQuery) {
        resourceOptions = {
          ...resourceOptions,
          ...this.resourceQuery
        }
      }

      if (this.parsedFilter) {
        let query = {}

        Object.keys(this.parsedFilter).forEach((k) => {
          const filter = this.searchFilters.find((f) => f.name === k)

          if (
            filter &&
            filter.modifyFilter &&
            typeOf(filter.modifyFilter) === 'function'
          ) {
            query = {
              ...query,
              ...filter.modifyFilter(this.parsedFilter[k])
            }
          } else {
            query[k] = this.parsedFilter[k]
          }
        })

        // NOTE: this is intended to fix a frontend quirk, where the frontend might set filters that do not
        // make sense for the backend. This fix is being called below and is mainly to avoid bad UI. However,
        // for consistency we are chopping of superfluous params for the backend as well.
        if (typeOf(this.pruneSearchFilters) === 'boolean') {
          query = filterObject(
            query,
            (key, value) =>
              !!this.searchFilters.find((item) => {
                if (item.name === key) return true
                if (item.prop && item.prop === key) return true
                if (Array.isArray(item.prop) && item.prop.includes(key))
                  return true
                return false
              })
          )
        }

        if (typeOf(this.pruneSearchFilters) === 'function') {
          query = this.pruneSearchFilters(query)
        }

        safeSet(resourceOptions, 'query', {
          limit: this.resourceLimit,
          ...resourceOptions.query,
          ...query
        })
      }

      return resourceOptions
    },
    handleUpdateFilter(filter) {
      if (filter && !filter.originalData) return
      this.filtersData[filter.name] = filter.originalData
    },
    handleSearchFilterInput(v) {
      this.$emit('search-filter', v)
    },
    handleSearchFilterSubmit(v) {
      this.$emit('search-filter-submit', v)

      if (this.doRouteFilters) {
        const filter = qs.stringify({
          filter: mapValues(v, (value, key) => {
            if (value === null || value === undefined || value.value === null)
              return undefined
            return value.value
          })
        })

        const route = `${this.routeBaseDefault}?${filter}`
        this.$nextTick(() => {
          this.$emit('search-filter-submit-route', route)
          this.$router.push(route)
        })
      }
    }
  }
}
</script>
<style scoped>
.table-actions {
  height: var(--action-button-height);
}
</style>
